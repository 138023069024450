import { HttpStatusCode } from "axios";
import { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useApi } from "../../api/useApi";
import { DemoForm } from "./DemoForm";
import "./Footer.css";


export const Footer = () => {
	const { t } = useTranslation();
	// const [isTablet, setIsTablet] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [/* showAlert */, setShowAlert] = useState(false);
	const [mail, setMail] = useState("");
	const [/* alertMessage */, setAlertMessage] = useState("Lütfen mail kısmını doldurun");
	const [/* affirmative */, setAffirmative] = useState(false);
	const [mailSubmitBorderColor, setMailSubmitBorderColor] = useState("#C1C1C1");
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [captchaSub, setCaptchaSub] = useState(false);
	const api = useApi();

	const handleSubmit = async (event) => {
		if (captchaSub) {
			event.preventDefault();

			if (!mail) {
				setMailSubmitBorderColor("red");
				setShowErrorMessage(true);
				return;
			}

			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			if (!(emailRegex.test(mail))) {
				setMailSubmitBorderColor("red");
				setShowErrorMessage(true);
				return;
			}

			submitForm();
		}
	}

	const submitForm = async () => {
		try {
			const formData = new FormData();
			formData.append("email", new Blob([mail]));
			const response = await api.subscribe(formData);
			if (response.status === HttpStatusCode.Ok) {
				setAffirmative(true);
				setAlertMessage("Bizimle iletişim kurduğunuz için teşekkür ederiz!")
				setShowAlert(true);
				setMailSubmitBorderColor("#C1C1C1");
				setShowErrorMessage(false);
			}
		} catch (e) {
			setAffirmative(false);
			setAlertMessage("İletişim kurulurken bir hata oluştu.")
			setShowAlert(true);
		}
		setTimeout(() => {
			window.location.reload();
		}, 5000);
	}

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 800);
			// setIsTablet(window.innerWidth > 800 && window.innerWidth <= 1100);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	return (
		<div className={`${isMobile ? 'mobile' : ''} footerMainContainer`}>
			{
				showForm &&
				<DemoForm onClose={() => setShowForm(false)} />
			}
			<div className="footerFirstContainer">
				<div className="footer-text-container">
					<h2>{t("footerMainText")}</h2>
					<span>{t("footerSpanText")}</span>
				</div>
				<div className="buttons">
					<span className="button1 footer" onClick={() => {
						setShowForm(true);
					}}>{t("signUpText")}</span>
					{/* <a className="button2 footer" href="pricingOptions">{t("freeTrial")}</a> */}
				</div>
			</div>
			<div className="footerSecondContainer">
				<div className="joinUsText">{t("joinUsText")}</div>
				<div className="secondContainerSpanText">{t("joinUsSpan")}</div>
				<div className="mailSubmit">
					<div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
						<div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: "15px", alignItems: "center" }}>
							<input id="contact-email" className="footerInput" type="email" placeholder="hello@creative-tim.com" value={mail} onChange={(e) => setMail(e.target.value)} style={{ borderColor: mailSubmitBorderColor }}></input>
							<div className="subscribeButton" onClick={handleSubmit} style={{ backgroundColor: captchaSub ? "#1EA9CD" : "gray", cursor: captchaSub ? "pointer" : "default" }}>
								<div className="subscribeText">{t("subscribe")}</div>
							</div>
						</div>
						<div style={{
							display: showErrorMessage ? "flex" : "none",
							alignItems: "flex-end",
							justifyContent: "flex-start",
							gap: "10px",
							paddingTop: "0",
							alignSelf: "flex-start"
						}}>
							<img alt="error" src="/assets/img/footerImages/error.svg" />
							<span className="footerErrorMessage">{t('footerErrorMessage')}</span>
						</div>
					</div>
					{
						mail.length > 0 &&
						<ReCAPTCHA
							sitekey="6LcJ028pAAAAAD22zjJUhAuO3WuRSTXllcXfOcW3"
							className="reCaptchaFooter"
							onChange={val => setCaptchaSub(val)}
							{...captchaSub && { style: { display: "none" } }}
						/>
					}
				</div>
				<div className="social-media-links">
					<a href="https://twitter.com/RapidRenderApp"><img className="socialMediaImage" alt='twitter logo' src={'/assets/img/footerImages/x.svg'} /></a>
					<a href="https://www.linkedin.com/company/rapidrenderapp/"><img className="socialMediaImage" alt='linkedin logo' src={'/assets/img/footerImages/linkedIn.svg'} /></a>
					<a href="https://www.instagram.com/rapidrender.app/"><img className="socialMediaImage" alt='instagram logo' src={'/assets/img/footerImages/instagram.svg'} /></a>
				</div>
				<div className="legal-docs-links">
					<a href="/termsOfUse">{t('termsOfUse')}</a>
					<a href="/privacyPolicy">{t('privacyPolicy')}</a>
					<a href="/cookiePolicy">{t('cookiePolicy')}</a>
				</div>
				<div className="footer-links-divider"></div>
				<div className="copyright-text">Copyright © Sugartech | Designed by Elmira - Powered by Sugar</div>
			</div>
		</div>
	)
}